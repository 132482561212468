<template>
  <div class="page-content-template">
    <div class="header">
      <div class="style-title-setting-global">
        {{
          $i18n.locale === 'fr'
            ? 'Gestion des templates '
            : 'Template Management '
        }}
        {{ selectedMenu }}
      </div>

      <v-btn
        v-if="selectedMenu == 'e-mail'"
        class="btn-filter"
        dark
        color="#5C2DD3"
        @click.stop="initialisetinymce"
      >
        <v-icon class="sz-icon-plus mr-1">mdi-plus</v-icon>
        {{
          $i18n.locale === 'fr'
            ? 'Ajouter une template E-mail'
            : 'Add E-mail template'
        }}
      </v-btn>

      <v-btn
        v-if="selectedMenu == 'sms'"
        @click.stop="showTemplateSms"
        class="btn-filter"
        dark
        color="#5C2DD3"
      >
        <v-icon class="sz-icon-plus mr-1">mdi-plus</v-icon>
        {{
          $i18n.locale === 'fr'
            ? 'Ajouter une template SMS'
            : 'Add SMS template'
        }}
      </v-btn>
    </div>
    <div class="block-menu-users mt-2">
      <div class="list-tabs">
        <v-tabs
          bg-color="#5C2DD3"
          :hide-slider="false"
          slider-color="#5C2DD3"
          color="#5C2DD3"
          item-color="#5C2DD3"
          v-model="model"
        >
          <v-tab
            href="#tab-0"
            color="#5C2DD3"
            item-color="#5C2DD3"
            @click="clickMenu('e-mail')"
            to="/setting/templates/email"
          >
            <div class="menu-item">
              E-mail
            </div>
          </v-tab>
          <v-tab
            href="#tab-1"
            color="#5C2DD3"
            item-color="#5C2DD3"
            @click="clickMenu('sms')"
            to="/setting/templates/sms"
          >
            <div class="menu-item">
              SMS
            </div>
          </v-tab>
        </v-tabs>
      </div>
    </div>
    <div class="content">
      <router-view />
    </div>
    <!-- Model Template Email -->
    <v-dialog
      v-model="showTemlplateEmail"
      max-width="1200"
      persistent
      scrollable
      :retain-focus="false"
      :eager="true"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr'
                ? 'Ajouter une template E-mail'
                : 'Add E-mail Template'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="hideModalTemplete('showTemlplateEmail')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form ref="showTemlplateEmail" class="mt-2">
            <v-row>
              <v-col>
                <v-text-field
                  :label="$t('title')"
                  v-model="templateEmailToAdd.title"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('title') + ' ' + $t('msgOblg')]"
                >
                </v-text-field>

                <v-textarea
                  dense
                  :label="$t('object')"
                  v-model="templateEmailToAdd.subject"
                  :persistent-placeholder="true"
                  rows="2"
                  outlined
                  color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('object') + ' ' + $t('msgOblg')]"
                ></v-textarea>
              </v-col>
              <v-col>
                <div class="mb-1 title-fields">
                  {{ $i18n.locale === 'fr' ? 'Champs' : 'Fields' }}
                  <div
                    class="font-text font-sz-12 ml-3 color-crm"
                    v-if="copierText"
                  >
                    {{ $i18n.locale === 'fr' ? 'Text copier' : 'Text copy' }}
                  </div>
                </div>
                <div
                  v-if="
                    getEmailTemplatesFields && getEmailTemplatesFields.length
                  "
                >
                  <v-text-field
                    v-for="(prop, val) of getEmailTemplatesFields"
                    :key="prop.id"
                    :value="val"
                    dense
                    disabled
                    outlined
                    color="#5C2DD3"
                    item-color="#5C2DD3"
                    class="input-fields-copy"
                  >
                    <template #append>
                      <v-icon
                        class="color-crm"
                        :title="$t('copy')"
                        @click.prevent.stop="
                          CopyFields({ prop: prop, val: val })
                        "
                        >mdi-content-copy</v-icon
                      >
                    </template>
                  </v-text-field>
                </div>
                <div v-else class="div-aucun">
                  <div class="titre">
                    {{ $i18n.locale === 'fr' ? 'Aucun Champs' : 'No Fields' }}
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="showTemlplateEmail"
              ><v-col>
                <editor
                  v-model="templateEmailToAdd.body"
                  :init="computedDataTinymce.init"
                ></editor></v-col
            ></v-row>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getNewMailTemplateLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getTemplateEmailError" class="error-msg">
              <ul v-if="Array.isArray(getTemplateEmailError)">
                <li v-for="(e, index) in getTemplateEmailError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getTemplateEmailError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleNewEmailTemplate"
            :loading="loading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="hideModalTemplete('showTemlplateEmail')" small>{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Model Template SMS  -->
    <v-dialog
      v-model="templateSmsShow"
      max-width="800"
      persistent
      scrollable
      :retain-focus="false"
      :eager="true"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr'
                ? 'Ajouter une template SMS'
                : 'Add SMS Template'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="hideModalTemplete('templateSmsShow')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form ref="templateSmsShow" class="mt-2">
            <v-row>
              <v-col>
                <v-text-field
                  :label="$t('title')"
                  v-model="templateSmsToAdd.title"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('title') + ' ' + $t('msgOblg')]"
                >
                </v-text-field>

                <v-textarea
                  dense
                  :label="$t('message')"
                  v-model="templateSmsToAdd.message"
                  :persistent-placeholder="true"
                  rows="6"
                  outlined
                  color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('message') + ' ' + $t('msgOblg')]"
                ></v-textarea>
              </v-col>
              <v-col>
                <div class="mb-1 title-fields">
                  {{ $i18n.locale === 'fr' ? 'Champs' : 'Fields' }}
                  <div
                    class="font-text font-sz-12 ml-3 color-crm"
                    v-if="copierText"
                  >
                    {{ $i18n.locale === 'fr' ? 'Text copier' : 'Text copy' }}
                  </div>
                </div>
                <div
                  v-if="getSmsTemplatesFields && getSmsTemplatesFields.length"
                >
                  <v-text-field
                    v-for="(prop, val) of getSmsTemplatesFields"
                    :key="prop.id"
                    :value="val"
                    dense
                    disabled
                    outlined
                    color="#5C2DD3"
                    item-color="#5C2DD3"
                    class="input-fields-copy"
                  >
                    <template #append>
                      <v-icon
                        class="color-crm"
                        :title="$t('copy')"
                        @click.prevent.stop="
                          CopyFields({ prop: prop, val: val })
                        "
                        >mdi-content-copy</v-icon
                      >
                    </template>
                  </v-text-field>
                </div>
                <div v-else class="div-aucun">
                  <div class="titre">
                    {{ $i18n.locale === 'fr' ? 'Aucun Champs' : 'No Fields' }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getSmsTemplateLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getTemplateSmsError" class="error-msg">
              <ul v-if="Array.isArray(getTemplateSmsError)">
                <li v-for="(e, index) in getTemplateSmsError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getTemplateSmsError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleNewSmsTemplate"
            :loading="loading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="hideModalTemplete('templateSmsShow')" small>{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script src="//cdn.tinymce.com/4/tinymce.min.js"></script>
<script>
import Editor from '@tinymce/tinymce-vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      model: 'tab-0',
      selectedMenu:
        this.$router.history.current.name === 'templatesSms' ? 'sms' : 'e-mail',
      templateEmailToAdd: {
        title: null,
        subject: null,
        body: null,
        specific_field: null
      },
      showTemlplateEmail: false,
      templateSmsShow: false,
      templateSmsToAdd: {
        title: null,
        message: null,
        specific_field: null
      },
      copierText: false,
      image_list: [],
      loading: false
    }
  },
  methods: {
    ...mapActions([
      'fetchEmailTemplatesFields',
      'newEmailTemplate',
      'resetErrorEmailTemplate',
      'resetErrorSmsTemplate',
      'fetchSmsTemplatesFields',
      'newSmsTemplate',
      'setLisImage',
      'getAllImageTemplate'
    ]),
    clickMenu(menu) {
      this.selectedMenu = menu
    },

    showTemplateSms() {
      this.templateSmsShow = true
      this.templateSmsToAdd = {
        title: null,
        message: null,
        specific_field: null
      }
    },
    async handleNewEmailTemplate() {
      if (this.$refs.showTemlplateEmail.validate()) {
        const specific_field = []
        if (this.templateEmailToAdd.body != null) {
          for (const [key, value] of Object.entries(
            this.getEmailTemplatesFields
          )) {
            if (this.templateEmailToAdd.body.indexOf(value) !== -1) {
              specific_field.push(value)
            }
          }
        }

        const body =
          '<!DOCTYPE html><html><head><meta charset="UTF-8"> <title>' +
          this.templateEmailToAdd.title +
          '</title></head><body>' +
          this.templateEmailToAdd.body +
          '</body></html>'
        const response = await this.newEmailTemplate({
          template: {
            body: body,
            title: this.templateEmailToAdd.title,
            subject: this.templateEmailToAdd.subject,
            specific_field: specific_field
          }
        })
        if (response) {
          this.hideModalTemplete('showTemlplateEmail')
        }
      }
    },
    async handleNewSmsTemplate() {
      if (this.$refs.templateSmsShow.validate()) {
        if (
          this.templateSmsToAdd.title == null ||
          this.templateSmsToAdd.title == ''
        ) {
          this.$refs['title-template-sms-input'].reportValidity(
            this.$refs['title-template-sms-input'].validationMessage
          )
        } else {
          const specific_field = []
          if (this.templateSmsToAdd.message != null) {
            for (const [key, value] of Object.entries(
              this.getSmsTemplatesFields
            )) {
              if (this.templateSmsToAdd.message.indexOf(value) !== -1) {
                specific_field.push(value)
              }
            }
          }
          const response = await this.newSmsTemplate({
            template: {
              message: this.templateSmsToAdd.message,
              title: this.templateSmsToAdd.title,
              specific_field: specific_field
            }
          })
          if (response) {
            this.hideModalTemplete('templateSmsShow')
          }
        }
      }
    },
    initialisetinymce() {
      this.templateEmailToAdd.body = null
      this.showTemlplateEmail = true
    },
    resetTemplateModel(template) {
      this.templateEmailToAdd = {
        title: null,
        subject: null,
        body: null,
        specific_field: null
      }
      this.templateSmsToAdd = {
        title: null,
        message: null,
        specific_field: null
      }
      if (template === 'showTemlplateEmail') {
        this.resetErrorEmailTemplate()
      } else {
        this.resetErrorSmsTemplate()
      }
    },
    hideModalTemplete(template) {
      this[template] = false
      if (template == 'showTemlplateEmail') {
        this.$refs.showTemlplateEmail.resetValidation()
      }
      if (template == 'templateSmsShow') {
        this.$refs.templateSmsShow.resetValidation()
      }
      this.resetTemplateModel(template)
    },
    CopyFields(text) {
      // Create a temporary textarea element
      const copyTextarea = document.createElement('textarea')
      copyTextarea.style.position = 'fixed'
      copyTextarea.style.opacity = '0'
      copyTextarea.value = text

      // Append the textarea to the document
      document.body.appendChild(copyTextarea)

      // Select the text in the textarea
      copyTextarea.select()

      // Use the Clipboard API to copy the selected text
      if (navigator.clipboard) {
        this.copierText = true
        navigator.clipboard
          .writeText(text)
          .then(() => {})
          .catch(err => {})
          .finally(() => {
            // Remove the temporary textarea
            document.body.removeChild(copyTextarea)
            setTimeout(() => {
              this.copierText = false
            }, 800)
          })
      } else {
        // Remove the temporary textarea
        document.body.removeChild(copyTextarea)
      }
    }
  },
  computed: {
    ...mapGetters([
      'getEmailTemplatesFields',
      'getTemplateEmailError',
      'getNewMailTemplateLoading',
      'getSmsTemplatesFields',
      'getTemplateSmsError',
      'getSmsTemplateLoading',
      'getImageTemplateList'
    ]),
    computedImageList() {
      return this.getImageTemplateList.map(item => {
        return { title: item.title, value: item.url }
      })
    },
    computedDataTinymce() {
      return {
        init: {
          selector: '#editor',
          init_instance_callback: function(editor) {
            var freeTiny = document.querySelector(
              '.tox-notifications-container'
            )
            if (freeTiny) {
              freeTiny.style.display = 'none'
            }
          },
          height: 450,
          fullpage_default_doctype: '<!DOCTYPE html>',
          fullpage_default_encoding: 'UTF-8',
          plugins: [
            'advlist autolink lists link image  print preview hr',
            'searchreplace visualblocks code ',
            'insertdatetime  table paste imagetools charmap'
          ],
          fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt 50pt',

          toolbar_mode: 'floating',
          powerpaste_allow_local_images: true,
          language: this.$i18n.locale === 'fr' ? 'fr_FR' : 'en',
          toolbar:
            ' undo redo | bold italic underline | alignleft aligncenter alignright alignjustify |  link image |' +
            ' bullist  numlist outdent indent  |  forecolor backcolor  |' +
            '   print preview  fullpage |' +
            ' hr charmap',
          menubar: 'favs file edit view insert format   ',
          menu: {
            format: {
              title: 'Format',
              items:
                'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align | forecolor backcolor | removeformat '
            }
          },
          contextmenu: false,
          removed_menuitems: 'paste pastetext',
          block_formats: 'box=alert-box; badge=badge; footer=footer',
          formats: {
            'alert-box': {
              block: 'div',
              styles: {
                'background-color': '#f4f5f9',
                padding: '20px'
              }
            },
            footer: {
              block: 'div',
              styles: {
                'background-color': ' #c7c7c7',
                padding: '20px'
              }
            },
            badge: {
              title: 'Badge',
              inline: 'span',
              styles: {
                display: 'inline-block',
                border: '.1rem solid transparent',
                'border-radius': '50px',
                padding: '1rem',
                'margin-top': '0px',
                'margin-bottom': '1rem',
                color: '#2276d2',
                'box-shadow': '2px 3px #d6d7da',
                'background-color': '#008cd6',
                color: 'white'
              }
            }
          },
          /* enable title field in the Image dialog*/
          image_title: true,
          /* enable automatic uploads of images represented by blob or data URIs*/
          automatic_uploads: true,
          image_list: success => success(this.computedImageList),
          file_picker_types: 'image',
          images_upload_handler: async (blobInfo, success, failure) => {
            let param = new FormData()
            param.append('image', blobInfo.blob())
            this.setLisImage(param)
              .then(response => {
                success(response.data.data.url)
              })
              .catch(error => {
                failure('HTTP Error: ' + error.message)
              })
          }
        }
      }
    }
  },
  components: {
    Editor
  },
  mounted() {
    this.fetchEmailTemplatesFields()
    this.fetchSmsTemplatesFields()
    this.getAllImageTemplate()
  }
}
</script>

<style lang="scss" scoped>
.page-content-template {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .block-menu-users {
    .list-tabs {
      background-color: #f6f6f6;
      display: flex;
      align-items: center;
    }
    .v-tab {
      &:hover {
        text-decoration: none;
      }
    }
    v-tab .v-tab--active {
      &:hover {
        text-decoration: none;
      }
    }
    .menu-item {
      &.router-link-exact-active {
        text-decoration: none;
        font-weight: 700;
        font-size: 14px;
        color: #5c2dd3;
        letter-spacing: 0.4px;
        font-family: 'Montserrat', sans-serif;
      }
    }
    .v-tab {
      text-decoration: none;
      font-weight: 600;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
      letter-spacing: 0.4px;
      font-family: 'Montserrat', sans-serif;
      &.v-tab--active {
        text-decoration: none;
        font-weight: 700;
        font-size: 12px;
        color: #5c2dd3;
        letter-spacing: 0.4px;
        font-family: 'Montserrat', sans-serif;
      }
      &.v-tab--disabled {
        text-decoration: none;
        font-weight: 600;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);
        letter-spacing: 0.4px;
        font-family: 'Montserrat', sans-serif;
      }
    }
  }
}
</style>
<style lang="scss">
.input-fields-copy {
  .v-text-field__details {
    display: none;
  }
}
.tox-notifications-container {
  display: none !important;
}
.title-fields {
  display: flex;
}
</style>
